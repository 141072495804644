import PropTypes from 'prop-types';
import { cn } from '@/lib/utils';
import { Alert as AlertContainer, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { FormMessage } from '@/components/ui/form';
import Icon from '@/components/atoms/icon';
import Checkbox from '../checkbox';
import Button from '@/components/atoms/button';

// Note: need to put "border-" in this function otherwise the colours won't show
const getThemeBorderColour = (type) => {
	let themeColour = 'border-notificationsError';

	if (type === 'info') {
		themeColour = 'border-corporateBlue';
	} else if (type === 'success') {
		themeColour = 'border-notificationsSuccess';
	} else if (type === 'warning') {
		themeColour = 'border-notificationsWarning';
	} else if (type === 'bigger-icon') {
		themeColour = 'border-corporateBlue';
	} else if (type === 'claim-only') {
		themeColour = 'border-eis-purple';
	}

	return themeColour;
};

const getThemeColour = (type) => {
	let themeColour = 'notificationsError';

	if (type === 'info') {
		themeColour = 'corporateBlue';
	} else if (type === 'success') {
		themeColour = 'notificationsSuccess';
	} else if (type === 'warning') {
		themeColour = 'notificationsWarning';
	} else if (type === 'bigger-icon') {
		themeColour = 'corporateBlue';
	} else if (type === 'claim-only') {
		themeColour = 'eis-purple';
	}

	return themeColour;
};

const Alert = ({
	type,
	title,
	description,
	checkbox,
	priBtn,
	priBtnVariant,
	priBtnShowIcon,
	priBtnOnClick,
	secBtn,
	secBtnVariant,
	secBtnShowIcon,
	secBtnOnClick,
	className,
	biggerIcon,
	formMessage,
	...props
}) => {
	return (
		<AlertContainer
			className={cn('flex flex-col md:flex-row gap-6 items-start md:items-center justify-between', `${getThemeBorderColour(type)}`, className)}
			{...props}
		>
			{type !== 'bigger-icon' ? (
				<div className="flex flex-col gap-3">
					<div className="flex items-start gap-3">
						<Icon name={type} className={cn('w-6 h-6 shrink-0 mt-1', `text-${getThemeColour(type)}`)} />

						{/* Note: this is should be the same style as the H4 example in the Heading atom */}
						<AlertTitle className={cn(`text-${getThemeColour(type)} normal-case`)}>{title}</AlertTitle>
					</div>

					<div className="flex flex-col gap-6 ml-9">
						<AlertDescription className="text-eis-black">{description}</AlertDescription>

						{checkbox && (
							<Checkbox
								className="mt-1"
								wrapClassName="items-start"
								htmlFor={checkbox.htmlFor}
								label={checkbox.label}
								checked={checkbox.checked}
								onCheckedChange={checkbox.onCheckedChange}
							/>
						)}

						{formMessage && <FormMessage name={formMessage.name} meta={formMessage.meta} />}
					</div>
				</div>
			) : (
				<div className="flex flex-col gap-1">
					<div className="flex items-start gap-7">
						<Icon name={biggerIcon} className={cn('w-14 h-14 shrink-0', `text-${getThemeColour(type)}`)} />

						{/* Note: this is should be the same style as the H4 example in the Heading atom */}
						<AlertTitle className={cn(`text-${getThemeColour(type)} normal-case self-center md:self-start`)}>{title}</AlertTitle>
					</div>

					<div className="flex flex-col gap-6 mt-6 md:mt-0 md:ml-eis-84">
						<AlertDescription className="text-eis-black">{description}</AlertDescription>

						{checkbox && (
							<Checkbox
								className="mt-1"
								wrapClassName="items-start"
								htmlFor={checkbox.htmlFor}
								label={checkbox.label}
								checked={checkbox.checked}
								onCheckedChange={checkbox.onCheckedChange}
							/>
						)}

						{formMessage && <FormMessage name={formMessage.name} meta={formMessage.meta} />}
					</div>
				</div>
			)}

			{(priBtn || secBtn) && (
				<div className="flex flex-col items-start gap-4 md:items-end ml-9 md:ml-0">
					{priBtn && (
						<Button variant={priBtnVariant} onClick={priBtnOnClick} className="w-full md:w-max" {...(priBtnShowIcon && { icon: 'plus' })}>
							{priBtn}
						</Button>
					)}

					{secBtn && (
						<Button variant={secBtnVariant} onClick={secBtnOnClick} className="w-full md:w-max" {...(secBtnShowIcon && { icon: 'plus' })}>
							{secBtn}
						</Button>
					)}
				</div>
			)}
		</AlertContainer>
	);
};

export default Alert;

Alert.propTypes = {
	type: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	description: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
	checkbox: PropTypes.shape({
		htmlFor: PropTypes.string,
		label: PropTypes.string,
	}),
	priBtn: PropTypes.string,
	priBtnOnClick: PropTypes.func,
	priBtnVariant: PropTypes.oneOf([
		'primary',
		'primary-white',
		'secondary',
		'secondary-white',
		'icon',
		'circle-icon',
		'small-button',
		'underline',
		'nav-button',
		'nav-top-button',
		'outline',
		'ghost',
	]),
	priBtnShowIcon: PropTypes.bool,
	secBtn: PropTypes.string,
	secBtnOnClick: PropTypes.func,
	secBtnVariant: PropTypes.oneOf([
		'primary',
		'primary-white',
		'secondary',
		'secondary-white',
		'icon',
		'circle-icon',
		'small-button',
		'underline',
		'nav-button',
		'nav-top-button',
		'outline',
		'ghost',
	]),
	secBtnShowIcon: PropTypes.bool,
	className: PropTypes.string,
	biggerIcon: PropTypes.string,
};
Alert.defaultProps = {
	type: null,
	title: null,
	description: null,
	checkbox: null,
	priBtn: null,
	priBtnVariant: 'primary',
	priBtnShowIcon: true,
	secBtn: null,
	secBtnVariant: 'secondary',
	secBtnShowIcon: true,
	className: null,
	biggerIcon: null,
};
